import { app } from "../../config/firebase.config";
import { getAuth, createUserWithEmailAndPassword, fetchSignInMethodsForEmail } from "firebase/auth";
import { useState, useEffect } from "react";
import { CreateUser } from "../../api/index";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../hooks/useAuthContext";

const SignupUserForm = () => {
  const firebaseAuth = getAuth(app);
  const navigate = useNavigate();
  const { uid, auth, nav, email, dispatch: AuthDispatch } = useAuthContext();
  const [registerEmail, setRegisterEmail] = useState(email || "");
  const [registerPassword, setRegisterPassword] = useState("");
  const [firebaseError, setFirebaseError] = useState("")

  // AUTHENTICATE
  useEffect(() => {
    const authenticate = firebaseAuth.onAuthStateChanged((userCred) => {
      if (userCred) {
        AuthDispatch({ type: "SET_UID", payload: userCred?.uid });
        AuthDispatch({ type: "SET_USER", payload: userCred });
        AuthDispatch({ type: "SET_AUTH_STATUS", payload: true });
      } else {
        AuthDispatch({ type: "SET_UID", payload: null });
        AuthDispatch({ type: "SET_AUTH_STATUS", payload: false });
        AuthDispatch({ type: "SET_USER", payload: null });
      }
    });

    return () => authenticate();
  }, [firebaseAuth, AuthDispatch]);

  // SIGNUP
  const signUp = async (e) => {
    e.preventDefault();
    try {

      const providerData = await fetchSignInMethodsForEmail(
        firebaseAuth,
        registerEmail
      );

      if (providerData.length > 0) {
        setFirebaseError("Email address is already in use.");
        return;
      }

      const userCred = await createUserWithEmailAndPassword(
        firebaseAuth,
        registerEmail,
        registerPassword
      );

      if (userCred) {
        window.localStorage.setItem("auth", "true");
        CreateUser(userCred.user.accessToken);
        AuthDispatch({ type: "SET_UID", payload: userCred.user.uid });
        AuthDispatch({ type: "SET_EMAIL", payload: userCred?.user.email });
        AuthDispatch({ type: "SET_AUTH_STATUS", payload: true });
        AuthDispatch({ type: "NAVIGATE", payload: "signup" });
      }
    } catch (error) {
      setFirebaseError("Please provide valid email and password. Password must contain minimum 6 characters.");
    }
  };

  // NAVIGATE
  useEffect(() => {
    if (uid && auth && nav) {
      if (nav === "signup") {
        navigate("/new_account");
        AuthDispatch({ type: "NAVIGATE", payload: false });
      }
    }
  }, [uid, auth, nav, navigate]);

  return (
    <div>
      <form className="create" onSubmit={signUp}>
        <h3>Create an account</h3>
        <input
          placeholder={registerEmail ? registerEmail : "email..."}
          type="email"
          onChange={(e) => setRegisterEmail(e.target.value)}
        />
        <input
          placeholder="password..."
          type="password"
          onChange={(e) => setRegisterPassword(e.target.value)}
        />
        <button>Sign Up</button>
        {firebaseError && <div className="error">{firebaseError}</div>} {/* Display Firebase error */}
      </form>
    </div>
  );
};

export default SignupUserForm;
