const baseUrl = process.env.REACT_APP_BACKEND_URL;


export const UpdateUser = async (token) => {
  try {
    const res = await fetch(`${baseUrl}api/users/signin`, {
      method: "PATCH",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error; // Rethrow the error to handle it in the caller function.
  }
};

export const CreateUser = async (token) => {
  try {

    const res = await fetch(`${baseUrl}api/users/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    });

    if (!res.ok) {
      throw new Error("Network response was not ok");
    }

    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching user:", error);
    throw error; // Rethrow the error to handle it in the caller function.
  }
};

// **************** AUTHENTICATE USER FOR THE COMPANY ****************************

export const fetchLatestCompany = async (token, id) => {
  try {
    const res = await fetch(`${baseUrl}api/users/get-comp/${id}`, {
      method: "GET",
      headers: {
        Authorization: "Bearer " + token,
      },
    });

    if (res.ok) {
      const json = await res.json();
      return json;
    } else {
      throw new Error("Failed to fetch company data");
    }
  } catch (error) {
    console.error("Error fetching company:", error);
    throw error;
  }
};

export const fetchCompany = async (token, id) => {
  const res = await fetch(`${baseUrl}api/companies/${id}`, {
    method: "GET",
    headers: {
      Authorization: "Bearer " + token,
    },
  });
  if (res.ok) {
    const json = await res.json();
    return json;
  }
  if (!res.ok) {
    const errorJson = await res.json();
    const errorMessage = errorJson.error;
    throw new Error(errorMessage);
  }
};
