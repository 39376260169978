import { AuthContext } from "../context/AuthContext";
import { useContext } from "react";

// Hooks provide a way to use state and lifecycle methods in functional components without the need for class components
// this will give you the access to your context
// everytime we want to use this context we will invoke this context hook
// wraps the component tree that it wants to give the context to
export const useAuthContext = () => {
  const context = useContext(AuthContext);

  if (!context) {
    throw Error(
      "useCompanyContext must be used inside an CompaniesContextProvider"
    );
  }

  return context;
};
