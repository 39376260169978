
import ReactDOM from "react-dom";
import React, { lazy, useEffect } from 'react'
import "./index.css";
import { CompaniesContextProvider } from "./context/CompanyContext";
import { S116InputContextProvider } from "./context/S116Context";
import { EsrsContextProvider } from "./context/ESRSContext";
import { AuthContextProvider } from "./context/AuthContext";
import { BrowserRouter as Router } from "react-router-dom";
import AuthComp from "./pages/SignUpCompany";
import Auth from "./pages/SignUpUser";

window.Buffer = window.Buffer || require("buffer").Buffer;
const App = lazy(() => import('./App'));

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
      <AuthContextProvider>
        <EsrsContextProvider>
          <CompaniesContextProvider>
            <S116InputContextProvider>
              <App />
            </S116InputContextProvider>
          </CompaniesContextProvider>
        </EsrsContextProvider>
      </AuthContextProvider>
  </React.StrictMode>
);


// ***************************** OLD CODE *****************************
// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import { CompaniesContextProvider } from './context/CompanyContext';
// import { S116InputContextProvider} from './context/S116Context';
// import { EsrsContextProvider } from './context/ESRSContext'


// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <EsrsContextProvider>
//       <CompaniesContextProvider>
//         <S116InputContextProvider>
//           <App />
//         </S116InputContextProvider>
//       </CompaniesContextProvider>
//     </EsrsContextProvider>
//   </React.StrictMode>
// );
