import React, { createContext, useReducer } from "react";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
  switch (action.type) {
    case "SET_UID":
      return {
        ...state,
        uid: action.payload,
      };
    case "SET_AUTH_STATUS":
      return {
        ...state,
        auth: action.payload,
      };
    case "NAVIGATE":
      return {
        ...state,
        nav: action.payload,
      };
    case "SET_USER":
      return {
        ...state,
        user: action.payload,
      }
    case "SET_CID":
      return {
        ...state,
        cid: action.payload,
      };
    case "SET_EMAIL":
      return {
        ...state,
        email: action.payload,
      };
      case "SET_CONTRACT_ADDRESS":
      return {
        ...state,
        contractAddress: action.payload,
      };
    default:
      return state;
  }
};

export const AuthContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, {
    uid: null,
    auth: false,
    nav: false,
    cid: null,
    user: null,
    email: "",
    contractAddress: "",
  });

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
