import SignUpCompanyForm from "../components/auth/SignUpCompanyForm";
import logo from "../assets/image003.png";
import { useAuthContext } from "../hooks/useAuthContext";

const AuthComp = () => {
  return (

      <div className="create-container">
        <div className="form-container">
          <SignUpCompanyForm />
        </div>
        <div>
          {" "}
          <img
            src={logo}
            alt="Monadi"
            style={{ width: "400px", height: "auto" }}
          />
        </div>
      </div>

  );
};

export default AuthComp;
