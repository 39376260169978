import { createContext, useReducer } from "react";

// Context = allows data to be shared without having to pass them on as props
export const EsrsContext = createContext();

export const esrsReducer = (state, action) => {
  switch (action.type) {
    case "SET_ESRS":
      return {
        ...state,
        esrs: action.payload,
      };

    case "SET_CATEGORIES":
      return {
        ...state,
        categories: [...new Set(action.payload.map((c) => c.category))].sort(),
      };

    case "SET_DISCLOSURE":
      return {
        ...state,
        disclosures: action.payload,
      };
    default:
      return state;
  }
};

export const EsrsContextProvider = ({ children }) => {
  // dispatch function is a mechanism used to trigger actions that can lead to state updates within components
  const [state, dispatch] = useReducer(esrsReducer, {
    esrs: [],
    categories: null,
    disclosures: [],
  });
  return (
    <EsrsContext.Provider value={{ ...state, dispatch }}>
      {children}
    </EsrsContext.Provider>
  );
};
