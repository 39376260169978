import React from "react";
import SignupUserForm from "../components/auth/SignUpUserForm";
import logo from "../assets/image003.png";

const SignUp = () => {
  return (
    <div className="login-container">
      <div>
        {" "}
        <img
          src={logo}
          alt="Monadi"
          style={{ width: "400px", height: "auto" }}
        />
      </div>
      <div className="form-container">
        <SignupUserForm />
      </div>
    </div>
  );
};

export default SignUp;
