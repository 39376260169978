import { getApp, getApps, initializeApp} from 'firebase/app'

// ***************************************************************
// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAuth } from "firebase/auth"
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBDxlRJfm8ZMZLQ__upVXnHTj48leFfaY4",
  authDomain: "monadi-3fd4a.firebaseapp.com",
  projectId: "monadi-3fd4a",
  storageBucket: "monadi-3fd4a.appspot.com",
  messagingSenderId: "449154383955",
  appId: "1:449154383955:web:596d449d4d4e4373bd86a6",
  measurementId: "G-N9KXCB446R"
};

const app = getApps.length > 0 ? getApp() : initializeApp(firebaseConfig);
export {app};

// *************************************************************
// Initialize Firebase
// const app = initializeApp(firebaseConfig);
// export const auth = getAuth(app)
// const analytics = getAnalytics(app);
