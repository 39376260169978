import { createContext, useReducer } from "react";

// Context = allows data to be shared without having to pass them on as props
export const S116InputContext = createContext();

// state = the previous state that we use to make changes to it, previous state
// action = the new value
// return a new value that we want the state to be
export const s116InputReducer = (state, action) => {
  switch (action.type) {
    case "SET_INPUTS":
      return {
        ...state,
        inputs: action.payload,
      };

    case "SET_SPECIFIC_INPUTS":

      return {
        ...state,
        specific_inputs: action.payload,
      }
    default:
      return state;
  }
};

// this gives access to the data in every component has access to this component
// children = we output the root app component
// ALL COMPONENTS WILL HAVE ACCESS TO THE CONTEXT
export const S116InputContextProvider = ({ children }) => {
  // dispatch function is a mechanism used to trigger actions that can lead to state updates within components
  const [state, dispatch] = useReducer(s116InputReducer, {
    inputs: [],
    specific_inputs: [],
  });

  return (
    <S116InputContext.Provider value={{ ...state, dispatch }}>
      {children}
    </S116InputContext.Provider>
  );
};

//
