import { createContext, useReducer } from "react";

// Context = allows data to be shared without having to pass them on as props
export const CompaniesContext = createContext();

export const companiesReducer = (state, action) => {
  switch (action.type) {
    case "SETS_COMPANIES":
      return {
        companies: action.payload,
      };
    //
    case "CREATE_COMPANY":
      return {
        companies: [action.payload, ...state.companies],
      };

    case "CREATE_ONE_COMPANY":
      return {
        company: action.payload,
      };

    case "DELETE_COMPANY":

      return {
        companies: state.companies.filter((c) => c._id !== action.payload._id),
      };
    case "GET_COMPANY":
      return {
        // company: state.companies.filter((c) => c._id === action.payload._id),
        company: action.payload,
      };
    case "ADD_S116INPUT":
      return {
        company: action.payload,
      };
    default:
      return state;
  }
};

export const CompaniesContextProvider = ({ children }) => {
  // dispatch function is a mechanism used to trigger actions that can lead to state updates within components
  const [state, dispatch] = useReducer(companiesReducer, {
    companies: null,
    company: null,
  });

  return (
    <CompaniesContext.Provider value={{ ...state, dispatch }}>
      {children}
    </CompaniesContext.Provider>
  );
};

//
